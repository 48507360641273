<template>
  <div id="wrapper">
    <div id="afcfa-link-sidebar">
      <ul class="nav flex-column">
        <li class="nav-item ">
           <router-link class="sidebar-brand d-flex 
              align-items-center justify-content-center" to="/entity/dashboard">
              <div class="sidebar-brand-icon">
                <img height="80" src="../../assets/rsz_afcfta_link_2.png" alt="">
              </div>
            </router-link>
             <hr class="sidebar-divider">
        </li>
         <li class="nav-item active">
        <router-link class="nav-link" to="/entity/dashboard">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span> Dashboard</span></router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-parent="#collapseTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
          <i class="fas fa-tasks"></i> <span>Products Management</span> <i class="fas fa-angle-down"></i>  
        </a>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Custom Components:</h6>
              <router-link class="collapse-item" :to="`/entity/${currentEntity.entity.id}/newproduct`" >
              <i class="fas fa-fw fa-plus"></i> <span>New Product</span>
            </router-link>
            <router-link class="collapse-item"  :to="`/entity/${currentEntity.entity.id}/products`">
              <i class="fa fa-bars"></i> <span>My Products</span>
            </router-link>
              <router-link class="collapse-item" :to="`/products`" >
              <i class="fas fa-fw fa-list"></i> <span> All Products</span>
            </router-link>
          </div>
        </div>
      </li>
       
     
    </ul>
    </div>

    <div id="mainContainer">
        <!-- MAIN COMPONENT  -->
        <!-- TOP NAVIGATION COMPONENT -->
          <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <ul class="navbar-nav ml-auto">
            <button class="toggler open">
              <i class="fa fa-bars"></i>
            </button>
             <button class="toggler close">
              <i class="fa fa-times"></i>
            </button>
            <div class="topbar-divider d-none d-sm-block"></div>
            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" 
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">Welcome ! {{currentUser.individual.username}}</span>
                <img class="img-profile rounded-circle"  src="https://source.unsplash.com/QAB-WJcbgJk/60x60">
              </a>
              <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a class="dropdown-item" href="/user/entities">
                  <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  My Entities
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item my-link" @click="logout" data-toggle="modal" data-target="#logoutModal">
                  <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div>
            </li>

          </ul>

        </nav>
        <!-- END OF TOP NAVIGATION COMPONENT -->
        <div id="main" class="container-fluid">
          <router-view> </router-view>

            

              <footer class="sticky-footer bg-white">
                <button class="btn btn-danger scroll-top" data-scroll="up" type="button">
                <i class="fa fa-chevron-up"></i>
              </button>
              <div class="container my-auto">
                <div class="copyright text-center my-auto">
                  <p>Copyright &copy; AfCFTA APP</p>
                  <p>In association with the <span style="color:black; font-weight:bolder"> African Union Open Corridors Initiative</span> </p>
                  <p>In partnership with the <span style="color:black; font-weight:bolder">AfCFTA Secretariat </span></p>
                </div>
              </div>
          </footer>

        </div>
    </div>
  </div>
</template>

<script>

import url from '../../helpers/url'
import service from '../../helpers/service'
import Storage from '../../helpers/storage'

const $ = require('jquery')
export default {
  name:'layout',
  data() {
    return {
      isClicked:false,
      currentEntity:{},
      currentUser:{}
    }
  },
  methods: {
     toggleClick() {
       alert('i am clicked')
       this.isClicked = !this.isClicked
     },
     getCurrentEntity() {
      this.currentEntity = Storage.get('currentEntity')
    },
    getCurrentUser() {
      this.currentUser = Storage.get('currentUser')
    },
      logout() {
          Storage.removeAll()
          this.$session.remove('entity')
          window.location.replace(`${url()}/accounts/logout?${service()}`)

        },
  },
  computed: {
    // currentEntity() {
    //   return this.$store.getters.currentEntity 
    // },
    // currentUser() {
    //   return this.$store.getters.currentUser
    // },
    fullYear() {
      return new Date().getFullYear()
    }
  },
  mounted() {
    $(document).ready(function() {

      const close = $('.close')
      const open = $('.open')

      close.on('click', function(){
        $(this).hide()
        open.show()
         $('#afcfa-link-sidebar').toggleClass('show-sidebar')
        $('#mainContainer').toggleClass('increase-width')
      })

      open.on('click', function(){
        $(this).hide()
        close.show()
         $('#afcfa-link-sidebar').toggleClass('show-sidebar')
        $('#mainContainer').toggleClass('increase-width')
      })

      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('.scroll-top').fadeIn();
        } else {
          $('.scroll-top').fadeOut();
        }
      });

      $('.scroll-top').click(function () {
        $("html, body").animate({
          scrollTop: 0
        }, 100);
          return false;
      });

    })
  },
  created() {
    this.getCurrentUser()
    this.getCurrentEntity()
  }
}
</script>

<style scoped>
.scroll-top {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 25px;
  right: 20px;
  display: none;
}
.scroll-top i {
  display: inline-block;
  color: #fff;
}
.collapse-header{
  padding: 10px 0;
  opacity: 0.5;
}
#collapseTwo{
  margin:10px;
}
.collapse-inner{
  padding:10px;
}
.collapse-inner a{
  display: block;
  color:#000;
  opacity: .6;
  padding: 8px 0
}

#mainContainer{
  transition: margin-left .5s;
  width: 100%;
  margin-left: 210px;
  margin-right: 0px;
  padding-right: 0!important;
  padding-left: 0!important;
}
#afcfa-link-sidebar{
  height: 100%;
  width: 210px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #ba2428;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
}
#afcfa-link-sidebar .nav-link{
  color:#fff;
}
.sidebar-divider {
  background-color:rgb(231, 220, 220) !important;
}
.toggler {
	outline: none !important;
  border: none;
  position: absolute;
  left: 10px;
  background: #fff !important;
  top: 20px;
}
.close{
  display: none;
}
.open{
  visibility: hidden;
}
.toggler .fa-bars, .toggler .fa-times{
	color: #ba2428;
	font-size: 30px !important;
}
.show-sidebar{
  width: 180px!important;
}
.increase-width{
  margin-left: 180px!important;
}
/* MEDIA QUERIES */
@media screen and (max-width: 767px) {
  .open{
    visibility:visible;
  }
  #afcfa-link-sidebar{
    width:0px;
  }
  #mainContainer{
  transition: margin-left .5s;
  margin-left: 0px;
}
}
</style>