<template>
 <div fluid class="container main-content-container card px-4">
    <!-- Page Header -->
    <div no-gutters class="row page-header py-4">
      <div  col sm="4" class=" col text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Dashboard</span>
        
      </div>
    </div>
    <div class="container">
      <div class="card-deck text-white py-5">
          <div class="card-block card-body text-white" style="background:linear-gradient(40deg,#ff2020, #05ffa3);">
            <h4 class="card-title text-capitalize text-white">My Entity</h4>
          
             <p class="card-text">
              Name: {{currentEntity && currentEntity.entity.name}}
            </p>
            <p class="card-text">
              AfCFTA Number: {{currentEntity && currentEntity.entity.afcfta_number}}
            </p>
          
          </div>
        <!-- <router-link to="/users" class="card card-link card-small text-center">
          <div class="card-block card-body bg-danger">
            <h4 class="card-title text-uppercase text-white">Users</h4>
            <p class="card-text display-3 text-white">
              44
            </p>
          </div>
          <div class="card-footer bg-danger text-white">
    
          </div>
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Storage from '../../helpers/storage'
export default {
  name:'home',
  data() {
    return {
      entities:null,
      entity:null,
      // currentUser:{},
      currentEntity:{}
    }
  },
  methods: {
    getCurrentEntity() {
      this.currentEntity = Storage.get('currentEntity')
      console.log(this.currentEntity)
    },
    // getCurrentUser() {
    //   this.currentUser = Storage.get('currentUser')
    //   console.log(this.currentUser)
    // },
  },
  computed: {
    // currentEntity() {
    //   return Storage.get('currentEntity')
    // },
  },
  created() {
    this.getCurrentEntity()
    // this.getCurrentUser()
  }
}
</script>

<style scoped>
.bg-info{
  background: #05ffa3 !important;
}
select option {
  padding: 20px !important;
}
.card-text{
  font-size: 1.7em;
  display: inline;
  padding-right: 3em;
}
</style>